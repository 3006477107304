import { createSlice } from '@reduxjs/toolkit';
import { IMaterial } from '../../interfaces/IMaterial';

export const MATERIALES_DOMINIO = 'materiales';

export interface IMaterialesState {
  materiales: IMaterial[];
  loading: boolean;
}

const initialState: IMaterialesState = {
  materiales: [],
  loading: false,
}

const materialesSlice = createSlice({
  name: MATERIALES_DOMINIO,
  initialState,
  reducers: {
    RefrescarMateriales: (state, { payload }) => {
      state.materiales = payload.materiales;
    },
    AgregarMaterial: (state, { payload }) => {
      state.materiales.push(payload.material);
    },
    EliminarMaterial: (state, { payload }) => {
      // TODO: define
    },
    UpdateMaterial: (state, { payload }) => {
      const index = state.materiales.findIndex(m => m.id === payload.oldId);
      state.materiales[index] = payload.material;
      state.materiales[index].id = payload.id;
    },
    SetLoading: (state, { payload }) => {
      state.loading = payload.loading;
    }
  },
});


export const {
  RefrescarMateriales,
  AgregarMaterial,
  EliminarMaterial,
  UpdateMaterial,
  SetLoading,
} = materialesSlice.actions;

export default materialesSlice.reducer;