import React, { useState, useEffect, useContext } from "react";
import Modal from "../../../../components/Modal/Modal";
import Select from "../../../../components/Select/Select";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import { doCalculo, round } from "../../../../helpers/math";
import { bindActionCreators } from "@reduxjs/toolkit";
import { loadSistemas } from "../../../../store/sistemas/actions";
import { loadMateriales } from "../../../../store/materiales/actions";
import { connect } from "react-redux";
import { loadModulos } from "../../../../store/modulos/actions";
import { agregarModuloToSistema } from "../../../../store/presupuestos/actions";
import { IModuloPresupuesto } from "../../../../interfaces/IPresupuesto";
import MainContext from "../../../../context/MainContext";

const ModuloModal: React.FC<any> = ({
  sistema,
  isOpen,
  setIsOpen,
  sistemas,
  actions,
  materiales,
  modulos,
  marcacion,
}) => {
  const { setErrorMessage } = useContext(MainContext);
  const [saving, setSaving] = useState(false);
  const [modulo, setModulo] = useState(null);
  const [cantidad, setCantidad] = useState(0);
  const [alto, setAlto] = useState(null);
  const [ancho, setAncho] = useState(null);
  const [variable, setVariable] = useState(null);
  const [precioUnitario, setPrecioUnitario] = useState(0);
  const [precioFinal, setPrecioFinal] = useState(0);
  const [availableModulos, setAvailableModulos] = useState([]);

  useEffect(() => {
    actions
      .loadSistemas()
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obteniendo sistemas",
          e.message,
          "presupuesto/ModuloModal/useEffect"
        )
      );
    actions
      .loadModulos()
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obteniendo modulos",
          e.message,
          "presupuesto/ModuloModal/useEffect"
        )
      );
    actions
      .loadMateriales()
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obteniendo materiales",
          e.message,
          "presupuesto/ModuloModal/useEffect"
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const localSistema = sistemas.find((s) => s.id === sistema.sistemaId);
    if (!localSistema)
      return setErrorMessage(
        "Hubo un error buscando el sistema seleccionado " + sistema.sistemaId,
        JSON.stringify(sistemas),
        "presupuesto/ModuloModal/useEffect"
      );
    const avModulos = modulos.filter((m) => {
      for (let avMod of localSistema.modulos_disponibles) {
        if (avMod.nombre === m.nombre) return true;
      }
      return false;
    });
    setAvailableModulos(avModulos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sistemas, modulos]);

  useEffect(() => {
    if (!isOpen) {
      setAlto(null);
      setAncho(null);
      setModulo(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (ancho === null || alto === null) return;
    const selectedMod = {
      ...availableModulos.find((am) => am.nombre === modulo.label),
    };
    if (selectedMod) {
      selectedMod.ancho = ancho;
      selectedMod.alto = alto;
      const precio = doCalculo(selectedMod, materiales);
      setPrecioUnitario(precio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ancho]);

  useEffect(() => {
    const variableFix = !variable ? 1 : variable;
    setPrecioFinal(round(cantidad * precioUnitario * variableFix * marcacion));
  }, [cantidad, precioUnitario, variable, marcacion]);

  const handleClick = (e) => {
    try {
      setSaving(true);
      const newModulo: IModuloPresupuesto = {
        alto,
        ancho,
        cantidad: cantidad.toString(),
        variable,
        precioUnitario,
        precioFinal,
        modulo: modulo.label,
        moduloId: modulo.value,
      };
      actions.agregarModuloToSistema(sistema, newModulo);
      setIsOpen(false);
    } catch (e) {
      setErrorMessage(
        "Hubo un error agregando el modulo",
        e.message,
        "presupuesto/ModuloModal/handleClick"
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)} height="450px">
      <Input
        className="fwidth-item"
        type="text"
        placeholder="Cantidad"
        label="Cantidad"
        handleChange={(e) => setCantidad(e.target.value)}
      />
      <Select
        className="fwidth-item"
        label="Modulo"
        seleccionar={true}
        onChange={(e) => setModulo(e)}
        options={availableModulos.map((am) => ({
          label: am.nombre,
          value: am.id,
        }))}
        _value={modulo}
      />
      <Input
        className="fwidth-item"
        label="Alto"
        handleChange={(e) => setAlto(e.target.value)}
      />
      <Input
        className="fwidth-item"
        label="Ancho"
        handleChange={(e) => setAncho(e.target.value)}
      />
      <Input
        className="fwidth-item"
        type="text"
        placeholder="Variable"
        label="Variable"
        handleChange={(e) => setVariable(e.target.value)}
      />
      <Button color="blue" handleOnClick={handleClick} saving={saving}>
        Guardar
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    sistemas: state?.sistemas?.lista,
    materiales: state?.materiales?.materiales,
    modulos: state?.modulos?.lista,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        loadSistemas,
        loadMateriales,
        loadModulos,
        agregarModuloToSistema,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(ModuloModal);
