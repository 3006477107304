import * as mathjs from 'mathjs';
import { IMaterial } from '../interfaces/IMaterial';

export const round = (num) => {
  return Math.round(num * 100) / 100;
};

export const doCalculo = (modulo: any, materiales: IMaterial[]) => {
  const {
    ancho,
    alto,
    variables,
    calculo
  } = modulo;

  try {
    const parser = mathjs.parser();

    for(let m of materiales) {
      parser.evaluate(`${m.material} = ${m.precio}`);
    }

    parser.evaluate(`ancho = ${ancho}`);
    parser.evaluate(`alto = ${alto}`);

    for (let v of variables) {
      const currentVar = {
        nombre: v.nombre,
        formula: v.formula
      };
      if(v.redondeo) {
        const result = parser.evaluate(`${currentVar.formula}`);
        currentVar.formula = `${Math.floor(result)}`;
      }
      parser.evaluate(`${currentVar.nombre} = ${currentVar.formula}`);
    }

    const ret = parser.evaluate(calculo);
    console.log("ret", ret, ancho,alto);
    if(typeof ret === 'object') return 'NaN';
    return ret.toFixed(2);
  } catch (e) {
    return "NaN";
  }
};

export const doPartialCalculo = (modulo: any, materiales: IMaterial[], index) => {
  const {
    ancho,
    alto,
    variables,
  } = modulo;

  try {
    const parser = mathjs.parser();

    for(let m of materiales) {
      parser.evaluate(`${m.material} = ${m.precio}`);
    }

    parser.evaluate(`ancho = ${ancho}`);
    parser.evaluate(`alto = ${alto}`);

    for(let i = 0; i < variables.length; i++) {
      const currentVar = {
        nombre: variables[i].nombre,
        formula: variables[i].formula
      };
      if(variables[i].redondeo) {
        const result = parser.evaluate(`${currentVar.formula}`);
        currentVar.formula = `${Math.floor(result)}`;
      }
      if(i < index) {
        parser.evaluate(`${currentVar.nombre} = ${currentVar.formula}`);
      }
      else {
        return parser.evaluate(`${currentVar.formula}`).toFixed(2);
      }
    }
  } catch (e) {
    return "NaN";
  }
};