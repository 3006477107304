import { createSlice } from '@reduxjs/toolkit';
import { ISistema } from '../../interfaces/ISistema';

export const SISTEMAS_DOMINIO = 'sistemas';

export interface ISistemasState {
  lista: ISistema[];
  loading: boolean;
}

const initialState: ISistemasState = {
  lista: [],
  loading: false,
};

const sistemasSlice = createSlice({
  name: SISTEMAS_DOMINIO,
  initialState,
  reducers: {
    RefrescarSistemas: (state, { payload }) => {
      state.lista = payload.sistemas;
    },
    AgregarSistema: (state, { payload }) => {
      state.lista.push(payload.sistema);
    },
    UpdateSistema: (state, { payload }) => {
      const index = state.lista.findIndex(el => el.id === payload.sistema.id);
      state.lista[index] = payload.sistema;
      if(!payload.sistema.activo) state.lista.splice(index, 1);
    },
    SetLoading: (state, { payload }) => {
      state.loading = payload.loading;
    }
  },
});


export const {
  RefrescarSistemas,
  AgregarSistema,
  //EliminarMatrial,
  UpdateSistema,
  SetLoading,
} = sistemasSlice.actions;

export default sistemasSlice.reducer;