import config from '../config/config';

const gappsSignIn = (google, gapi) => {
    let tokenClient;
    const init = async (callback) => {
        const client = google.accounts.oauth2.initTokenClient({
            client_id: config.gappsClientId,
            // eslint-disable-next-line
            scope: 'https://www.googleapis.com/auth/spreadsheets \
                    https://www.googleapis.com/auth/drive',
            callback: response => {
                localStorage.setItem('gappsSignedIn', 'true');
                localStorage.setItem('clientToken', response.access_token);
                //setIsGappsSignedIn(true);
                console.log(response)
                callback();
            }
        });
        client.requestAccessToken();
    }

    const handleAuthClick = async () => {
        tokenClient.callback = async (resp) => {
            if (resp.error) throw (resp);
        };

        if (gapi.client.getToken() === null) {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            tokenClient.requestAccessToken({prompt: 'consent'});
        } else {
            // Skip display of account chooser and consent dialog for an existing session.
            tokenClient.requestAccessToken({prompt: ''});
        }
    }

    /* const handleAuthClick = async () => {
        gapi.accounts.id.prompt(notification => {
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                console.log("Sadly", notification)
            } else console.log("happily", notification);
            requestAccess();
        });
    }*/
    const handleSignOutClick = () => {
        /*google.accounts.id.disableAutoSelect();*/
        const token = gapi.client.getToken();
        if (token !== null) {
            //setIsGappsSignedIn(false);
            localStorage.setItem('gappsSignedIn', 'false');
        }
    }
    const initClient = async () => {
        try{
            /*await gapi.accounts.id.initialize({
                client_id: config.gappsClientId,
                callback: response => {

                },
            })*/
            init();
        } catch(err) {
            throw err;
        }
    }
    const handleClientLoad = () => {
        const i = setInterval(() => {
            if(google?.accounts?.id) {
                clearInterval(i);
                initClient();
            }
        }, 200);
    }
    return {
        handleClientLoad,
        handleAuthClick,
        handleSignOutClick,
        init
    }
};

export default gappsSignIn;