import materiales from './materiales/slice';
import sistemas from './sistemas/slice';
import modulos from './modulos/slice';
import presupuestos from './presupuestos/slice';
import { configureStore } from '@reduxjs/toolkit'
import logs from './logs/slice';

export const store = configureStore({
  reducer: {
    materiales,
    sistemas,
    modulos,
    presupuestos,
    logs,
  },
});
