import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "../../components/Button/Button";
import Panel from "../../components/Panel/Panel";
import SearchFilter from "../../components/SearchFilter";
import Table from "../../components/Table/Table";
import { loadModulos, eliminarModulo } from "../../store/modulos/actions";
import MainContext from "../../context/MainContext";

const Modulos = ({ actions, modulos, loading }) => {
  const { setErrorMessage } = useContext(MainContext);
  const [filteredModulos, setFilteredModulos] = useState([]);
  const [deleting, setDeleting] = useState([]);
  
  const load = () => {
    actions
      .loadModulos()
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obteniendo modulos",
          e.message,
          "Modulos/useEffect"
        )
      );
  };
  
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredModulos(modulos);
  }, [modulos]);

  const doDelete = (modulo) => {
    try {
      setDeleting([...deleting, modulo.id]);
      actions.eliminarModulo(modulo);
    } catch (e) {
      setDeleting(deleting.filter((d) => d !== modulo.id));
      setErrorMessage(
        "Hubo un error eliminando el modulo",
        e.message,
        "Modulos/useEffect"
      );
    }
  };

  const getDeleting = (modulo) => deleting.includes(modulo.id);

  return (
    <Panel title="Modulos" reload={load}>
      <header className="flex justify-flex-end align-center mb-2">
        <SearchFilter list={modulos} setFilteredList={setFilteredModulos} />
      </header>
      <Table
        columns={["Nombre"]}
        data={filteredModulos}
        keys={["nombre"]}
        editLink={{ to: "/modulo/", key: "id" }}
        delete={doDelete}
        deleting={getDeleting}
        duplicate={{ to: "/modulo/duplicar/", key: "id" }}
        loading={loading}
      />
      <footer className="presupuestos-footer">
        <Button color="green" className="ml-15" link="/modulo">
          Nuevo
        </Button>
      </footer>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    modulos: state?.modulos?.lista || [],
    loading: state?.modulos?.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        loadModulos,
        eliminarModulo,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modulos);
