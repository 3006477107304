import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadSistemas, eliminarSistema } from "../../store/sistemas/actions";
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";
import MainContext from "../../context/MainContext";

const Sistemas: React.FC<any> = ({ actions, sistemas, loading }) => {
  const { setMessage, setErrorMessage } = useContext(MainContext);
  const [deleting, setDeleting] = useState([]);
  
  const load = () => {
    actions.loadSistemas().catch((e) => {
      setErrorMessage(
        "Hubo un error obteniendo sistemas",
        e.message,
        "Sistemas/useEffect"
      );
    });
  };
  
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSistema = async (sistema) => {
    try {
      setDeleting([...deleting, sistema.id]);
      await actions.eliminarSistema(sistema);
      setMessage({
        type: "success",
        message: "Sistema eliminado correctamente",
      });
    } catch (e) {
      setErrorMessage(
        "Hubo un error al eliminar sistema",
        e.message,
        "Sistemas/deleteSistema"
      );
      setDeleting(deleting.filter((id) => id !== sistema.id));
    }
  };

  const getDeleting = (sistema) => deleting.includes(sistema.id);

  return (
    <Panel title="Sistemas" reload={load}>
      <Table
        columns={["Sistema"]}
        data={sistemas}
        keys={["nombre"]}
        editLink={{ to: "/sistema/", key: "id" }}
        loading={loading}
        delete={deleteSistema}
        deleting={getDeleting}
      />
      <footer className="presupuestos-footer">
        <Button color="green" className="ml-15" link="/sistema">
          Nuevo
        </Button>
      </footer>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    sistemas: state?.sistemas?.lista || [],
    loading: state?.sistemas?.loading
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        loadSistemas,
        eliminarSistema,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Sistemas);
