import React, { useState, useEffect, useContext } from "react";
import Modal from "../../../../components/Modal/Modal";
import Select from "../../../../components/Select/Select";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import { bindActionCreators } from "@reduxjs/toolkit";
import { loadSistemas } from "../../../../store/sistemas/actions";
import { connect } from "react-redux";
import { addSistemaToPresupuesto } from "../../../../store/presupuestos/actions";
import MainContext from "../../../../context/MainContext";

const SistemaModal = ({ isOpen, setIsOpen, actions, sistemas }) => {
  const { setErrorMessage } = useContext(MainContext);
  const [referencia, setReferencia] = useState("");
  const [sistema, setSistema] = useState(null);
  const handleClick = async (e) => {
    try {
      await actions.addSistemaToPresupuesto(
        sistema.label,
        sistema.value,
        referencia
      );
      setIsOpen(false);
    } catch (e) {
      setErrorMessage("", e.message, "presupuesto/SistemaModal/handleClick");
    }
  };

  useEffect(() => {
    actions
      .loadSistemas()
      .catch((e) =>
        setErrorMessage(
          "Error cargando sistemas",
          e.message,
          "presupuesto/SistemaModal/useEffect"
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)} height="250px">
      <Select
        className="fwidth-item"
        label="Tabique"
        seleccionar={true}
        onChange={(e) => setSistema(e)}
        options={sistemas?.map((s) => ({ label: s.nombre, value: s.id }))}
        _value={sistema}
      />
      <Input
        className="fwidth-item"
        type="text"
        placeholder="Referencia"
        label="Referencia"
        handleChange={(e) => setReferencia(e.target.value)}
      />
      <Button color="blue" handleOnClick={handleClick}>
        Guardar
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    sistemas: state?.sistemas?.lista || [],
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        loadSistemas,
        addSistemaToPresupuesto,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(SistemaModal);
