import React, { useState, useEffect } from "react";
import Input from "../Input/Input";

const SearchFilter = ({ list, setFilteredList }) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const search = () => {
    const filteredList = [];
    for (let item of list) {
      if (typeof item == "object") {
        for (let [key, value] of Object.entries(item)) {
          console.log(key);
          if (!Array.isArray(value) && String(value).includes(query)) {
            filteredList.push(item);
            break;
          }
        }
      } else {
        if (item.includes(query)) filteredList.push(item);
      }
      setFilteredList(filteredList);
    }
  };

  return (
    <Input
      placeholder="Search"
      handleChange={(e) => setQuery(e.target.value)}
    />
  );
};

export default SearchFilter;
