import { IMaterial } from '../../interfaces/IMaterial';
import service from './../../services/serviceLayer';
import {
  RefrescarMateriales,
  AgregarMaterial,
  UpdateMaterial,
  SetLoading
} from './slice';


export const refrescarMateriales = (materiales) => async (dispatch) => {

  dispatch(RefrescarMateriales({ materiales }))
};

export const agregarMaterial = (material) => async (dispatch) => {
  material.activo = true;
  const id = await service.insert('materiales', material);
  material.id = id;
  dispatch(AgregarMaterial({ material }))
};

export const eliminarMaterial = (material) => dispatch => {
  //
};

export const updateMaterial = (_material: IMaterial, nombre, unidad, precio) => async dispatch => {
  const material = { ..._material };
  const { id: oldId } = material;
  delete material.id;

  material.activo = false;
  await service.update('materiales', oldId, material);
  
  material.precio = precio;
  material.material = nombre;
  material.unidad = unidad;
  material.activo = true;
  const id = await service.insert('materiales', material);
  
  dispatch(UpdateMaterial({ oldId, id, material }));

}

export const loadMateriales = () => async (dispatch) => {
  dispatch(SetLoading({ loading: true }));
  const materiales = await service.getAllCondition('materiales', 'activo', '==', true);
  dispatch(RefrescarMateriales({ materiales }));
  dispatch(SetLoading({ loading: false }));
}
