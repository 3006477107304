import { createSlice } from '@reduxjs/toolkit';
import { ILog } from '../../interfaces/ILog';

export const LOG_DOMINIO = 'logs';
export interface ILogsState {
  lista: ILog[];
  loading: boolean;
}

const initialState: ILogsState = {
  lista: [],
  loading: false,
};

const logSlice = createSlice({
  name: LOG_DOMINIO,
  initialState,
  reducers: {
    RefrescarLogs: (state, { payload }) => {
      state.lista = payload.logs;
    },
    EliminarLog: (state, { payload }) => {
      const index = state.lista.findIndex(item => item.id === payload.log.id);
      state.lista.splice(index, 1);
    },
    SetLoading: (state, { payload }) => {
      state.loading = payload.loading;
    }
  },
});


export const {
  RefrescarLogs,
  EliminarLog,
  SetLoading,
} = logSlice.actions;

export default logSlice.reducer;