import { IMaterial } from "../../interfaces/IMaterial";
import { IModulo } from "../../interfaces/IModulo";
import {
  IModuloPresupuesto,
  ISistemaPresupuesto,
} from "../../interfaces/IPresupuesto";
import service from "../../services/serviceLayer";
import {
  RefrescarPresupuestos,
  AgregarPresupuesto,
  UpdatePresupuesto,
  LoadPresupuestoSelected,
  UpdatePresupuestoSelected,
  ActualizarValores,
  NewPresupuestoSelected,
  AddSistemaToPresupuesto,
  EliminarSistemaFromPresupuesto,
  EliminarModuloFromSistema,
  AgregarModuloToSistema,
  RefreshPrecioFinal,
  SetLoading,
} from "./slice";

export const agregarPresupuesto = (presupuesto) => async (dispatch) => {
  const id = await service.insert("presupuesto", presupuesto);
  presupuesto.id = id;
  await service.update("presupuesto", presupuesto.id, presupuesto); 
  dispatch(AgregarPresupuesto({ presupuesto }));
};

export const eliminarPresupuesto = (_presupuesto) => async (dispatch) => {
  const presupuesto = { ..._presupuesto };
  presupuesto.deleted = true;
  await service.update("presupuesto", presupuesto.id, presupuesto);
  dispatch(UpdatePresupuesto({ presupuesto }));
};

export const updatePresupuesto = (presupuesto) => async (dispatch) => {
  await service.update("presupuesto", presupuesto.id, presupuesto);
  dispatch(UpdatePresupuesto({ presupuesto }));
};

export const loadPresupuestos = () => async (dispatch) => {
  dispatch(SetLoading({ loading: true }));
  const presupuestos = await service.getAllCondition(
    "presupuesto",
    "deleted",
    "==",
    false
  );
  dispatch(RefrescarPresupuestos({ presupuestos }));
  dispatch(SetLoading({ loading: false }));
};

export const loadPresupuestoSelected =
  (presupuestoId: string) => (dispatch) => {
    dispatch(LoadPresupuestoSelected({ presupuestoId }));
  };

export const updatePresupuestoSelected =
  (value: any, key: string) => async (dispatch) => {
    dispatch(UpdatePresupuestoSelected({ value, key }));
  };

export const actualizarValores =
  (materiales: IMaterial[], modulos: IModulo[]) => async (dispatch) => {
    dispatch(ActualizarValores({ materiales, modulos }));
  };

export const newPresupuestoSelected = () => async (dispatch) => {
  dispatch(NewPresupuestoSelected());
};

export const addSistemaToPresupuesto =
  (sistema, sistemaId, referencia) => (dispatch) => {
    dispatch(AddSistemaToPresupuesto({ sistema, sistemaId, referencia }));
  };

export const eliminarSistemaFromPresupuesto =
  (sistema: ISistemaPresupuesto) => (dispatch) => {
    dispatch(EliminarSistemaFromPresupuesto({ sistema }));
  };

export const eliminarModuloFromSistema =
  (sistema: ISistemaPresupuesto, modulo: IModuloPresupuesto) => (dispatch) => {
    dispatch(EliminarModuloFromSistema({ sistema, modulo }));
  };

export const agregarModuloToSistema =
  (sistema: ISistemaPresupuesto, modulo: IModuloPresupuesto) => (dispatch) => {
    dispatch(AgregarModuloToSistema({ sistema, modulo }));
  };

export const refreshPrecioFinal = () => async (dispatch) => {
  dispatch(RefreshPrecioFinal());
};
