import service from './../../services/serviceLayer';
import {
  RefrescarModulos,
  AgregarModulo,
  UpdateModulo,
  SetLoading,
} from './slice';


export const refrescarModulos = (modulos) => async (dispatch) => {
  dispatch(RefrescarModulos({ modulos }))
};

export const agregarModulo = (_modulo) => async (dispatch) => {
  console.log(_modulo)
  const modulo = { ..._modulo };
  modulo.activo = true;
  delete modulo.id;
  const id = await service.insert('modulos', modulo);
  modulo.id = id;
  dispatch(AgregarModulo({ modulo }))
};

export const eliminarModulo = (_modulo) => async dispatch => {
  const modulo = { ..._modulo };
  modulo.activo = false;
  await service.update('modulos', modulo.id, modulo);  
  dispatch(UpdateModulo({ modulo }));
};

export const updateModulo = (modulo) => async dispatch => {
  await service.update('modulos', modulo.id, modulo);  
  dispatch(UpdateModulo({ modulo }));
}

export const loadModulos = () => async (dispatch) => {
  dispatch(SetLoading({ loading: true }));
  const modulos = await service.getAllCondition('modulos', 'activo', '!=', false);
  dispatch(RefrescarModulos({ modulos }));
  dispatch(SetLoading({ loading: false }));
}
