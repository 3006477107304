import React from "react";
import "./Button.scss";
import { Link } from "react-router-dom";
import ellipsis from "./img/ellipsis.gif";

const Button: React.FC<any> = ({
  children,
  handleOnClick,
  link,
  color,
  className,
  disabled,
  fullwidth,
  saving,
  borderless,
  noHover = false,
}) => {
  return link ? (
    <Link
      to={link}
      className={`button ${color} ${className} ${disabled} ${
        saving ? "saving" : ""
      }`}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`button ${color} ${
        noHover ? "no-hover" : ""
      } ${className} ${disabled} ${fullwidth ? "fullwidth" : ""} ${
        saving ? "saving" : ""
      } ${borderless ? "borderless" : ""}`}
      type="button"
      disabled={disabled}
      onClick={handleOnClick}
    >
      {saving ? (
        <img src={ellipsis} alt="ellipsis" className="ellipsis" />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
