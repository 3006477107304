import React, {useContext, useState} from 'react';
import { animated, useSpring } from 'react-spring'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import MainContext from '../../context/MainContext';
import './MainHeader.scss';
import LogoPivot from '../../img/logo-pivot.jpg';

const MainHeader = () => {
  const {firebase, user} = useContext(MainContext);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuListProps=useSpring({
    height: showUserMenu ? "50px": "0px"
  });
  return (
    <header id="main-header">
      <div className="img-container">
        <a href="/"><img src={LogoPivot} alt="Logo Pivot" /></a>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap' }}>
        {user?.email ? <span style={{ marginRight: '10px' }}>{user.email}</span> : null }
        <section className="user-menu" onClick={() => setShowUserMenu(!showUserMenu)}>
          <FontAwesomeIcon icon={faUserAlt as any} />
          <animated.ul className="user-menu-list" style={userMenuListProps}>
            <li>
              <button type="button" onClick={e => {firebase.signOut()}}>Salir</button>
            </li>
          </animated.ul>
        </section>
      </div>
    </header>
  );
};

export default MainHeader;