import service from './../../services/serviceLayer';
import {
  RefrescarLogs,
  EliminarLog,
  SetLoading
} from './slice';

export const eliminarLog = (log) => async dispatch => {
  await service.delete('logs', log.id,);  
  dispatch(EliminarLog({ log }));
};

export const loadLogs = () => async (dispatch) => {
  dispatch(SetLoading({ loading: true }));
  const logs = await service.getAll('logs');
  console.log(logs);
  dispatch(RefrescarLogs({ logs }));
  dispatch(SetLoading({ loading: false }));
}
