import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Panel.scss';

const Panel = ({title, children, ...props}) => {
  const reloadClick = () => props.reload();

  return (
    <section className="panel">
      <header className="panel-header">
        <h2>{title}</h2>
        {props.reload ? <FontAwesomeIcon className="reload" icon={faRedo as any} onClick={reloadClick}/> : null }
      </header>
      <div className="panel-content">
        {children}
      </div>
    </section>
  );
};

export default Panel;