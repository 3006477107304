import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import MainContext from "../../context/MainContext";
import { loadLogs, eliminarLog } from '../../store/logs/actions';

const Logs: React.FC<any> = ({ actions, logs, loading }) => {
  const { setErrorMessage } = useContext(MainContext);

  const load = () => {
    actions
    .loadLogs()
    .catch((e) =>
      setErrorMessage(
        "Hubo un error obteniendo logs",
        e.message,
        "Logs/useEffect"
      )
    );
  }

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Panel title="Logs" reload={load}>
      <Table
        columns={["Mensaje", "Source"]}
        data={logs}
        keys={["message", "source"]}
        loading={loading}
        delete={actions.eliminarLog}
      />
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    logs: state?.logs?.lista,
    loading: state?.logs?.loading,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        loadLogs,
        eliminarLog,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Logs);
