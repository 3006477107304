import { createSlice } from '@reduxjs/toolkit';
import { IModulo } from '../../interfaces/IModulo';

export const MODULOS_DOMINIO = 'modulos';
export interface IModulosState {
  lista: IModulo[];
  loading: boolean;
}

const initialState: IModulosState = {
  lista: [],
  loading: false,
};

const modulosSlice = createSlice({
  name: MODULOS_DOMINIO,
  initialState,
  reducers: {
    RefrescarModulos: (state, { payload }) => {
      state.lista = payload.modulos;
    },
    AgregarModulo: (state, { payload }) => {
      state.lista.push(payload.modulo);
    },
    UpdateModulo: (state, { payload }) => {
      const index = state.lista.findIndex(item => item.id === payload.modulo.id);
      state.lista[index] = payload.modulo;
      if(!payload.modulo.activo) state.lista.splice(index, 1);
    },
    SetLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
  },
});


export const {
  RefrescarModulos,
  AgregarModulo,
  UpdateModulo,
  SetLoading,
} = modulosSlice.actions;

export default modulosSlice.reducer;