import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";
import MainContext from "../../context/MainContext";
import User from "../../models/User";
import useAuthBlocker from "../../hooks/useAuthBlocker/useAuthBlocker";

const Usuarios: React.SFC<any> = () => {
  const { firebase, setMessage, setErrorMessage } = useContext(MainContext);
  const [lista, setLista] = useState([]);
  const { isAuthorized, NotAuthorized } = useAuthBlocker("usuarios");
  const [loading, setLoading] = useState(false);

  const load = () =>{ 
    if (!firebase) return;
    setLoading(true);
    User.getAll(firebase)
      .then((data) =>
        setLista(data.map((item) => ({ id: item.id, email: item.email })))
      )
      .catch((e) => {
        setErrorMessage(
          "Hubo un error obteniendo lista de usuarios",
          e.message,
          "Usuarios/useEffect"
        );
      }).finally(() => setLoading(false));
  };
   
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase, setMessage]);

  if (!isAuthorized) return <NotAuthorized />;
  return (
    <Panel title="Usuarios" reload={load}>
      <Table
        columns={["email"]}
        keys={["email"]}
        data={lista}
        editLink={{ to: "/usuario/", key: "id" }}
        loading={loading}
      />
      <footer className="panel-footer">
        <Button
          color="green"
          className="ml-15"
          handleClick={(e) => {}}
          link="/usuario"
        >
          Nuevo
        </Button>
      </footer>
    </Panel>
  );
};

export default Usuarios;
