import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Panel from "../../components/Panel/Panel";
import Context from "../../context/MainContext";
import { createPresupuestoFile } from "../../services/sheets";
import DataForm from "./components/DataForm/DataForm";
import SistemaModal from "./components/SistemaModal/SistemaModal";
import Button from "../../components/Button/Button";
import Sistema from "./components/Sistema/Sistema";

import useAuthBlocker from "../../hooks/useAuthBlocker/useAuthBlocker";
import MainContext from "../../context/MainContext";
import useHistorialPresupuesto from "../../hooks/useHistorialPresupuesto/useHistorialPresupuesto";
import { bindActionCreators } from "redux";
import { loadModulos } from "../../store/modulos/actions";
import { connect } from "react-redux";
import { loadMateriales } from "../../store/materiales/actions";
import {
  loadPresupuestos,
  loadPresupuestoSelected,
  agregarPresupuesto,
  actualizarValores,
  newPresupuestoSelected,
  refreshPrecioFinal,
} from "../../store/presupuestos/actions";
import { initialPresupuestoSelectedState } from "../../store/presupuestos/slice";
import { updatePresupuesto } from "../../store/presupuestos/actions";
import _ from 'lodash';

const Presupuesto = ({ actions, modulos, materiales, presupuestoSelected }) => {
  const { id: paramId }: any = useParams();
  const { init, setMessage, setErrorMessage } = useContext(MainContext);
  const [sistemaModal, setSistemaModal] = useState(false);
  const [writing, setWriting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updatingValues, setUpdatingValues] = useState(false);
  const { gapi } = useContext(Context);
  const { isAuthorized, NotAuthorized } = useAuthBlocker("cotizador");
  const { create: createHistorial } = useHistorialPresupuesto(paramId);

  useEffect(() => {
    actions
      .loadModulos()
      .catch((e) =>
        setErrorMessage(
          "Error cargando modulos",
          e.message,
          "presupuesto/useEffect"
        )
      );
    actions
      .loadMateriales()
      .catch((e) =>
        setErrorMessage(
          "Error cargando materiales",
          e.message,
          "presupuesto/useEffect"
        )
      );
    actions
      .loadPresupuestos()
      .then(() => {
        if (paramId) actions.loadPresupuestoSelected(paramId);
        else actions.newPresupuestoSelected();
      })
      .catch((e) =>
        setErrorMessage(
          "Error cargando presupuestos",
          e.message,
          "presupuesto/useEffect"
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (presupuestoSelected?.datos && presupuestoSelected?.sistemas)
      actions
        .refreshPrecioFinal()
        .catch((e) =>
          setErrorMessage(
            "Error actualizando precio final",
            e.message,
            "presupuesto/useEffect"
          )
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presupuestoSelected?.datos?.marcacion, presupuestoSelected?.sistemas]);

  const guardar = async () => {
    try {
      setSaving(true);
      if (!paramId) {
        await actions.agregarPresupuesto(presupuestoSelected);
        setMessage({ message: "Presupuesto creado", type: "success" });
      } else {
        await actions.updatePresupuesto(presupuestoSelected);
        setMessage({ message: "Presupuesto guardado", type: "success" });
      }
    } catch (e) {
      setErrorMessage(
        "Error guardando presupuesto",
        e.message,
        "presupuesto/guardar"
      );
    } finally {
      setSaving(false);
    }
  };

  const generarPresupuesto = async () => {
    const url = await createPresupuestoFile(gapi, presupuestoSelected);
    await createHistorial(presupuestoSelected.id, url, presupuestoSelected);
    setMessage({
      message: "Presupuesto generado exitosamente",
      type: "success",
    });
    setWriting(false);
  }

  const handleGenerarPresupuesto = async () => {
    setWriting(true);
    try {
      if(!gapi?.client?.getToken()) {
        // GAPI Connection
        await new Promise((res, rej) => {
          gapi.load("client", {callback: res, onerror: rej});
        });
        await gapi.client.init({
          apiKey: process.env.REACT_APP_GAPPS_API_KEY,
          discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        });
        /// GSI
        await init(generarPresupuesto);
      } else await generarPresupuesto();
    } catch (e) {
      setErrorMessage(
        "Error generando presupuesto",
        e.message,
        "presupuesto/handleGenerarPresupuesto"
      );
      setWriting(false);
    }
  };

  const actualizarValores = async () => {
    try {
      setUpdatingValues(true);
      await actions.loadModulos();
      await actions.loadMateriales();
      await actions.actualizarValores(materiales, modulos);
      setMessage({
        type: "success",
        message: "Valores actualizados correctamente",
      });
    } catch (e) {
      setErrorMessage(
        "Error actualizando valores",
        e.message,
        "presupuesto/actualizarValores"
      );
    } finally {
      setUpdatingValues(false);
    }
  };

  if (!isAuthorized) return <NotAuthorized />;
  if ((paramId && _.isEqual(presupuestoSelected, initialPresupuestoSelectedState)) || _.isEmpty(presupuestoSelected))
    return null;
  return (
    <Panel title="Presupuesto">
      <form>
        <DataForm />
        <SistemaModal isOpen={sistemaModal} setIsOpen={setSistemaModal} />
        {presupuestoSelected?.sistemas?.map((sistema, index) => (
          <Sistema
            key={`${sistema.sistema.label}-${index}-${sistema.referencia}`}
            sistema={sistema}
            marcacion={presupuestoSelected?.datos?.marcacion}
          />
        ))}
        <div className="flex justify-flex-end flex-align-center">
          <h4>Precio Total: ${presupuestoSelected?.precioTotal}</h4>
        </div>
        <div className="flex justify-flex-end flex-align-center">
          <Button
            color="pink"
            handleOnClick={actualizarValores}
            saving={updatingValues}
            className="ml-15"
          >
            Actualizar Valores
          </Button>
          <Button
            color="green"
            className="ml-15"
            handleOnClick={() => {
              setSistemaModal(true);
            }}
          >
            Agregar Sistema
          </Button>
          <Button
            className="ml-15"
            color="yellow"
            handleOnClick={guardar}
            saving={saving}
          >
            Guardar
          </Button>
          <Button
            disabled={writing ? "disabled" : ""}
            className="ml-15"
            color="blue"
            handleOnClick={handleGenerarPresupuesto}
            saving={writing}
          >
            Generar Presupuesto
          </Button>
          <Button className="ml-15" color="red" link="/presupuestos">
            Volver
          </Button>
        </div>
      </form>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    modulos: state?.modulos?.lista,
    materiales: state?.materiales?.materiales,
    presupuestos: state?.prespupestos?.lista || [],
    presupuestoSelected:
      state?.presupuestos?.presupuestoSelected ||
      initialPresupuestoSelectedState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        loadModulos,
        loadMateriales,
        loadPresupuestos,
        loadPresupuestoSelected,
        agregarPresupuesto,
        actualizarValores,
        newPresupuestoSelected,
        refreshPrecioFinal,
        updatePresupuesto,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Presupuesto);
