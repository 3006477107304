import React, { useState, useReducer, useEffect, useContext } from "react";
import { bindActionCreators } from "redux";
import {
  agregarModulo,
  updateModulo,
  loadModulos,
} from "../../store/modulos/actions";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Panel from "../../components/Panel/Panel";
import { doCalculo, doPartialCalculo } from "../../helpers/math";
import { loadMateriales } from "../../store/materiales/actions";
import "./index.scss";
import Variable from "./components/Variable";
import MainContext from "../../context/MainContext";

const variablesReducer = (state, action) => {
  let newState = [...state];
  switch (action.type) {
    case "load":
      return action.payload.data;
    case "agregar":
      newState.push({ nombre: null, formula: null, redondeo: false });
      return newState;
    case "update":
      newState[action.payload.index] = action.payload.data;
      return newState;
    case "eliminar":
      return newState.filter((item, index) => index !== action.payload.index);
    default:
      return state;
  }
};

const Modulo: React.FC<any> = ({ actions, modulos, history, materiales }) => {
  const { setMessage, setErrorMessage } = useContext(MainContext);
  const ancho = 0.1;
  const alto = 1.5;
  const isDuplicate = window.location.pathname.includes("duplicar");
  const { id }: any = useParams();
  const [modulo, setModulo] = useState(
    { ...modulos.find((m) => m.id === id) } || {}
  );
  const [nombre, setNombre] = useState(modulo?.nombre || null);
  const [calculo, setCalculo] = useState(modulo?.calculo || null);
  const [variables, dispatch] = useReducer(
    variablesReducer,
    modulo?.variables || []
  );
  const [saving, setSaving] = useState(false);
  const [calculado, setCalculado] = useState("");

  useEffect(() => {
    actions
      .loadMateriales()
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obteniendo materiales",
          e.message,
          "Modulo/useEffect"
        )
      );
    actions
      .loadModulos()
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obteniendo modulos",
          e.message,
          "Modulo/useEffect"
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mod = { ...modulos.find((m) => m.id === id) } || {};
    console.log(mod);
    setModulo(mod);
    setNombre(mod?.nombre || null);
    setCalculo(mod?.calculo || null);
    dispatch({ type: "load", payload: { data: mod?.variables || [] } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulos]);

  const guardar = async () => {
    try {
      setSaving(true);

      if (
        modulos.find(
          (item) => item.nombre.toLowerCase() === nombre.toLowerCase()
        ) &&
        !id
      ) {
        setSaving(false);
        setErrorMessage(
          "Hubo un error obtniendo modulos",
          `Modulo existente: ${nombre}`,
          "Modulo/guardar"
        );
        return;
      }

      const _modulo = {
        id: null,
        nombre,
        ancho,
        alto,
        calculo,
        variables,
        activo: true,
      };

      const res = calcular(_modulo);
      if (!res) {
        console.log("error on calcular");
        setSaving(false);
        return;
      }

      if (!isDuplicate) _modulo.id = modulo.id;

      delete _modulo.alto;
      delete _modulo.ancho;

      if (!id || isDuplicate) await actions.agregarModulo(_modulo);
      else await actions.updateModulo(_modulo);

      if (isDuplicate) history.push("/modulos");
      setMessage({ type: "success", message: "Modulo guardado exitosamente" });
    } catch (e) {
      setErrorMessage(
        "Hubo un error guardando modulo",
        e.message,
        "Modulos/guardar"
      );
    } finally {
      setSaving(false);
    }
  };

  const getPartialCalculo = (index) => {
    try {
      const _modulo = {
        id: null,
        nombre,
        ancho,
        alto,
        calculo,
        variables,
      };

      const ret = doPartialCalculo(_modulo, materiales, index);
      if (typeof ret === "object") return "NaN";
      return ret;
    } catch (e) {
      setErrorMessage(
        "Hubo un error obteniendo cálculo",
        e.message,
        "Modulo/getPartialCalculo"
      );
      return "NaN";
    }
  };

  const calcular = (modulo): boolean => {
    try {
      const calculado = doCalculo(modulo, materiales);
      setCalculado(calculado);
      if (calculado === "NaN") return false;
      return true;
    } catch (e) {
      setErrorMessage(
        "Hubo un error obteniendo cálculo",
        e.message,
        "Modulo/calcular"
      );
      return false;
    }
  };

  if (id && !nombre) return <div>Loading...</div>;

  return (
    <Panel title="Modulo">
      <Input
        value={nombre ?? ""}
        handleChange={(event) => setNombre(event.target.value)}
        label="Nombre"
        placeholder="Nombre"
        type="text"
        className="grid-item  hwidth-item"
        skeleton={!modulos}
      />
      <Input
        value={calculo ?? ""}
        handleChange={(event) => setCalculo(event.target.value)}
        label="Calculo"
        placeholder="Calculo"
        type="text"
        className="grid-item  hwidth-item"
        skeleton={!modulos}
      />
      <section className="p-inline-4">
        <h3>Variables</h3>
        {variables.map((v, index) => (
          <Variable
            key={`var-${index}-${v.nombre}`}
            index={index}
            v={v}
            getPartialCalculo={getPartialCalculo}
            dispatch={dispatch}
          />
        ))}
        <div className="flex justify-flex-end flex-align-center mt-2">
          <Button
            color="blue"
            className="ml-15"
            handleOnClick={() => dispatch({ type: "agregar" })}
          >
            Agregar Variable
          </Button>
        </div>
      </section>
      <footer className="flex justify-space-between flex-align-center mt-4">
        <div>
          <Button
            className="ml-15"
            color="yellow"
            handleOnClick={() =>
              calcular({ nombre, ancho, alto, calculo, variables })
            }
          >
            Probar Calculo
          </Button>
          <span style={{ fontWeight: 900, marginLeft: "15px" }}>
            ${calculado}
          </span>
        </div>
        <div className="flex flex-align-center justify-flex-end">
          <Button
            className="ml-15"
            color="green"
            handleOnClick={guardar}
            saving={saving}
          >
            Guardar
          </Button>
          <Button className="ml-15" color="red" link="/modulos">
            Volver
          </Button>
        </div>
      </footer>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    modulos: state?.modulos?.lista || [],
    materiales: state?.materiales.materiales || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      agregarModulo,
      updateModulo,
      loadMateriales,
      loadModulos,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modulo);
