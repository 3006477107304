import React, { useState, useEffect } from "react";

import LoginForm from "./components/LoginForm/LoginForm";
import MainHeader from "./components/MainHeader/MainHeader";
import MainContext from "./context/MainContext";
import Sidebar from "./components/Sidebar/Sidebar";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Presupuestos from "./pages/Presupuestos/Presupuestos";
import Presupuesto from "./pages/Presupuesto/Presupuesto";
import HistorialPresupuesto from "./pages/HistorialPresupuesto/HistorialPresupuesto";
import Usuarios from "./pages/Usuarios/Usuarios";
import Usuario from "./pages/Usuario/Usuario";
import gappsSignInService from "./services/gappsSignIn";
import firebase from "./services/firebase";

import "./App.scss";
import User from "./models/User";
import Modal from "./components/Modal/Modal";
import MainScreen from "./pages/MainScreen/MainScreen";
import Materiales from "./pages/Materiales";
import Sistemas from "./pages/Sistemas";
import Sistema from "./pages/Sistema";
import Modulos from "./pages/Modulos";
import Modulo from "./pages/Modulo";
import serviceLayer from "./services/serviceLayer";
import Logs from "./pages/Logs";

const google = (window as any).google;
const gapi = (window as any).gapi;

const App = () => {
  const [user, setUser] = useState(
    new User({
      id: null,
      email: null,
      roles: [],
    })
  );
  const [message, setMessage] = useState({ message: null, type: null });
  const [loading, setLoading] = useState(false);

  const setErrorMessage = (
    displayMessage: string,
    message: string,
    source: string
  ) => {
    setMessage({
      type: "error",
      message: displayMessage,
    });
    serviceLayer.insertLog(message, source);
  };

  const { init } =
    gappsSignInService(google, gapi);

  useEffect(() => {
    //handleClientLoad();
    firebase.onAuthStateChanged(async (user) => {
      if (user && user.email) {
        const _user = await User.getUserByEmail(firebase, user.email);/*new User({
          id: user.uid,
          email: user.email,
          roles: [],
        });*/
        _user
          .getCurrentRoles(firebase)
          .then((roles) => {
            setUser(new User({ ..._user, roles }));
          })
          .catch((e) => setErrorMessage("Error retrieving user data", e.message, "APP-UseEffect"));
      } else {
        setUser(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContext.Provider
      value={{
        init,
        gapi,
        firebase,
        user,
        setMessage,
        setErrorMessage,
        loading,
        setLoading,
      }}
    >
      <React.Fragment>
        <MainHeader></MainHeader>
        <main>
          <BrowserRouter>
            {!user ? (
              <LoginForm />
            ) : (
              <React.Fragment>
                <Sidebar></Sidebar>
                <section className="main-content">
                  <Switch>
                    <Route exact path="/usuarios">
                      <Usuarios />
                    </Route>
                    <Route exact path="/usuario">
                      <Usuario />
                    </Route>
                    <Route exact path="/usuario/:id">
                      <Usuario />
                    </Route>
                    <Route exact path="/">
                      <MainScreen />
                    </Route>
                    <Route exact path="/presupuestos">
                      <Presupuestos></Presupuestos>
                    </Route>
                    <Route exact path="/presupuesto">
                      <Presupuesto></Presupuesto>
                    </Route>
                    <Route exact path="/presupuesto/:id">
                      <Presupuesto></Presupuesto>
                    </Route>
                    <Route exact path="/presupuesto/:pid/historial">
                      <HistorialPresupuesto></HistorialPresupuesto>
                    </Route>
                    <Route exact path="/materiales">
                      <Materiales></Materiales>
                    </Route>
                    <Route exact path="/sistemas">
                      <Sistemas></Sistemas>
                    </Route>
                    <Route exact path="/sistema">
                      <Sistema></Sistema>
                    </Route>
                    <Route exact path="/sistema/:id">
                      <Sistema></Sistema>
                    </Route>
                    <Route exact path="/modulos">
                      <Modulos></Modulos>
                    </Route>
                    <Route exact path="/modulo">
                      <Modulo></Modulo>
                    </Route>
                    <Route exact path="/modulo/:id">
                      <Modulo></Modulo>
                    </Route>
                    <Route
                      exact
                      path="/modulo/duplicar/:id"
                      render={(props) => <Modulo {...props} />}
                    />
                    <Route exact path="/logs">
                      <Logs />
                    </Route>
                  </Switch>
                </section>
              </React.Fragment>
            )}
          </BrowserRouter>
        </main>
      </React.Fragment>
      <Modal
        isOpen={message.message}
        contentCentered={true}
        closeModal={(e) => setMessage({ message: null, type: null })}
        height={200}
        width={350}
        type={message.type}
      >
        {message.message}
      </Modal>
    </MainContext.Provider>
  );
};

export default App;
