import React, { useState, useContext } from "react";
import "./Sistema.scss";
import useMinimizer from "../../../../hooks/useMinimizer";
import Table from "../../../../components/Table/Table";
import Button from "../../../../components/Button/Button";
import ModuloModal from "../ModuloModal/ModuloModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  eliminarModuloFromSistema,
  eliminarSistemaFromPresupuesto,
} from "../../../../store/presupuestos/actions";
import { connect } from "react-redux";
import MainContext from "../../../../context/MainContext";

const Sistema = ({ sistema, actions, marcacion }) => {
  const { setErrorMessage } = useContext(MainContext);
  const [deleting, setDeleting] = useState([]);
  const [moduloModal, setModuloModal] = useState(false);
  const { hiddenClass, Icon } = useMinimizer(false);

  const deleteModulo = (item) => {
    setDeleting([...deleting, item]);
    try {
      actions.eliminarModuloFromSistema(sistema, item);
    } catch (e) {
      setDeleting(deleting.filter((d) => d !== item));
      setErrorMessage(
        "Error eliminando modulo",
        e.message,
        "presupuesto/Sistema/deleteModulo"
      );
    }
  };

  //TODO: Fix validation - works without this for now
  const getDeleting = (modulo) => deleting.includes(modulo);

  return (
    <section className={`section-sistema ${hiddenClass}`}>
      <h3 className="title flex justify-space-between flex-align-center">
        <div>
          {sistema.sistema} - {sistema.referencia}
          <Icon />
        </div>
        <Button
          color="red"
          handleOnClick={() => actions.eliminarSistemaFromPresupuesto(sistema)}
        >
          <FontAwesomeIcon icon={faTrash as any} />
        </Button>
      </h3>
      <Table
        delete={deleteModulo}
        deleting={getDeleting}
        columns={[
          "Modulo",
          "Cantidad",
          "Alto",
          "Ancho",
          "Variable",
          "Precio Unitario",
          "Precio Final",
        ]}
        keys={[
          "modulo",
          "cantidad",
          "alto",
          "ancho",
          "variable",
          "precioUnitario",
          "precioFinal",
        ]}
        data={sistema.modulos}
      />
      <div className="flex justify-flex-end flex-align-center mt-25">
        <Button
          color="green"
          handleOnClick={() => {
            setModuloModal(true);
          }}
        >
          Agregar Modulo
        </Button>
      </div>
      {moduloModal ? (
        <ModuloModal
          sistema={sistema}
          isOpen={moduloModal}
          setIsOpen={setModuloModal}
          marcacion={marcacion}
        />
      ) : null}
    </section>
  );
};

const mapStateToProps = (state) => ({
  presupuestoSelected: state?.presupuestos?.presupuestoSelected,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      eliminarSistemaFromPresupuesto,
      eliminarModuloFromSistema,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sistema);
