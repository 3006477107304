import React from 'react';

const NotAuthorized = () => {
    return (
        <div>
            No estas autorizado para estar aca.
        </div>
    );
}

export default NotAuthorized;
