import React, { useState, useEffect, useContext } from "react";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";
import "./HistorialPresupuesto.scss";
import MainContext from "../../context/MainContext";
import useAuthBlocker from "../../hooks/useAuthBlocker/useAuthBlocker";
import useHistorialPresupuesto from "../../hooks/useHistorialPresupuesto/useHistorialPresupuesto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const HistorialPresupuesto = () => {
  const [lista, setLista] = useState([]);
  const { pid }: any = useParams();
  const { setMessage, setErrorMessage } = useContext(MainContext);
  const { isAuthorized, NotAuthorized } = useAuthBlocker("cotizador");
const { getAllByPresupuestoId, loading/*, getPresupuestoById */} = useHistorialPresupuesto();

  const load = () => {
    if (!isAuthorized) return;
    getAllByPresupuestoId(pid)
      .then((data) =>
        data.map((item) => ({
          nroPresupuesto: item.presupuesto.datos.nroPresupuesto,
          cliente: item.presupuesto.datos.cliente,
          obra: item.presupuesto.datos.obra,
          fecha: moment(item.fecha)
            .tz("America/Argentina/Buenos_Aires")
            .format("DD-MM-YYYY hh:mm:ss"),
          version: item.version || null,
          url: (
            <a target="blank" href={item.url}>
              <FontAwesomeIcon icon={faDownload as any} />
            </a>
          ),
        }))
      )
      .then((data) => setLista(data))
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obteniendo el historial",
          e.message,
          "HistorialPresupuesto/useEffect"
        )
      );
  }
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, setMessage]);

  /*const convertPresupuestoToPdf = (presupuestoId: string) => {
    const pdf = new jsPdf();
    const doc = new jsPdfDocument(pdf);
    getPresupuestoById(presupuestoId)
      .then((presupuesto) => {
        const { datos } = presupuesto;
        const {
          nroPresupuesto,
          cliente,
          obra,
          fecha,
          version,
        } = datos;
        doc.setProperties({
          title: `Presupuesto ${nroPresupuesto}`,
          subject: `Presupuesto ${nroPresupuesto}`,

        })
        doc.setFontSize(20);
        doc.text(`Presupuesto ${nroPresupuesto}`, 10, 10);
        doc.setFontSize(12);
        doc.text(`Cliente: ${cliente}`, 10, 20);
        doc.text(`Obra: ${obra}`, 10, 30);
        doc.text(`Fecha: ${fecha}`, 10, 40);
        doc.text(`Version: ${version}`, 10, 50);
        doc.setFontSize(16);
        doc.text(`Materiales`, 10, 60);
        doc.setFontSize(12);
        doc.text(`Cantidad`, 10, 70);
        doc.text(`Descripcion`, 20, 70);
        doc.text(`Precio`, 100, 70);
        doc.text(`Total`, 150, 70);
        doc.setFontSize(12);
        presupuesto.materiales.forEach((material, index) => {
          doc.text(material.cantidad, 10, 80 + index * 10);
          doc.text(material.descripcion, 20, 80 + index * 10);
          doc.text(material.precio, 100, 80 + index * 10);
          doc.text(material.total, 150, 80 + index * 10);
        });
        doc.setFontSize(16);
        doc.text(`Mano de obra`, 10, 80 + presupuesto.materiales.length * 10);
      })
  };*/

  if (!isAuthorized) return <NotAuthorized />;
  return (
    <Panel title={`Historial de Presupuestos - ${pid}`} reload={load}>
      <Table
        columns={[
          "No Presupuesto",
          "Cliente",
          "Obra",
          "Fecha",
          "Version",
          "Descargar",
        ]}
        loading={loading}
        data={lista}
        edit={false}
      />
      <footer className="presupuestos-footer">
        <Button link="/presupuestos" color="red">
          Volver
        </Button>
      </footer>
    </Panel>
  );
};

export default HistorialPresupuesto;
