import { createSlice } from '@reduxjs/toolkit';
import { doCalculo } from '../../helpers/math';
import { IPresupuesto } from '../../interfaces/IPresupuesto';

export const PRESUPUESTOS_DOMINIO = 'presupuestos';

export interface IPresupuestoState {
  lista: IPresupuesto[];
  presupuestoSelected: IPresupuesto;
  loading: boolean;
}

export const initialPresupuestoSelectedState: IPresupuesto = {
  datos: {
    bajada: '',
    cliente: '',
    destinatario: '',
    domicilio: '',
    email: '',
    marcacion: '',
    nroPresupuesto: '',
    obra: '',
    pie: '',
  },
  id: null,
  createdDate: '',
  updatedDate: '',
  deleted: false,
  precioTotal: 0,
  sistemas: [],
};

const initialState: IPresupuestoState = {
  lista: [],
  presupuestoSelected: { ...initialPresupuestoSelectedState },
  loading: false,
};

const presupuestosSlice = createSlice({
  name: PRESUPUESTOS_DOMINIO,
  initialState,
  reducers: {
    RefrescarPresupuestos: (state, { payload }) => {
      state.lista = payload.presupuestos;
    },
    AgregarPresupuesto: (state, { payload }) => {
      const presupuesto = { ...payload.presupuesto };
      presupuesto.createdDate = new Date().toDateString();
      presupuesto.updatedDate = new Date().toDateString();
      state.lista.push(presupuesto);
    },
    UpdatePresupuesto: (state, { payload }) => {
      const index = state.lista.findIndex(el => el.id === payload.presupuesto.id);
      const presupuesto = { ...payload.presupuesto };
      presupuesto.updatedDate = new Date().toDateString();
      state.lista[index] = presupuesto;
      if(presupuesto.deleted) state.lista.splice(index, 1);
    },
    LoadPresupuestoSelected: (state, { payload }) => {
      const index = state.lista.findIndex(el => el.id === payload.presupuestoId);
      if(index < 0) throw new Error('Presupuesto seleccionado no existe o no está activo');
      state.presupuestoSelected = state.lista[index];
    },
    NewPresupuestoSelected: (state) => {
      state.presupuestoSelected = { ...initialPresupuestoSelectedState } as IPresupuesto;
    },
    UpdatePresupuestoSelected: (state, { payload: { value, key } }) => {
      state.presupuestoSelected.datos[key] = value;
      if(key === 'marcacion') {
        state.presupuestoSelected.sistemas = state.presupuestoSelected.sistemas.map(s => ({
            ...s,
            modulos: s.modulos.map((m) => ({...m,
              precioFinal:
                    m.precioUnitario *
                    Number(m.cantidad) *
                    Number(m.variable || 1) *
                    Number(value)
              })
            ),
          })
        );
      }
    },
    AddSistemaToPresupuesto: (state, { payload: { sistema, sistemaId, referencia } }) => {
      state.presupuestoSelected.sistemas.push({ sistema, referencia, sistemaId, modulos: [] })
    },
    EliminarSistemaFromPresupuesto: (state, { payload: { sistema }}) => {
      const index = state.presupuestoSelected.sistemas.findIndex(s => s.sistema === sistema.sistema && sistema.sistemaId === s.sistemaId && sistema.referencia === s.referencia && sistema.modulos.length === s.modulos.length);
      if(index < 0) throw new Error('Error buscando el sistema');
      state.presupuestoSelected.sistemas.splice(index, 1);
    },
    ActualizarValores: (state, { payload: { modulos, materiales } }) => {
      let sistemas = [...state.presupuestoSelected.sistemas];
      state.presupuestoSelected.sistemas = sistemas.map((s) => {
        return {
          ...s,
          modulos: s.modulos.map((m) => {
            let ancho, alto;
            let activeModulo = modulos.find(
              m2 => {
                if (m2.id === m.moduloId) {
                  ancho = m.ancho; 
                  alto = m.alto;
                  return true;
                }
                return false;
              }
            );
            if (!activeModulo) return m;
            activeModulo = { ...activeModulo, alto, ancho };
            const precioUnitario = doCalculo(activeModulo, materiales);
            return {
              ...m,
              id: activeModulo.id,
              precioUnitario,
              precioFinal:
                Number(m.variable || 1) * Number(m.cantidad) * precioUnitario * Number(state.presupuestoSelected.datos.marcacion || 1),
            };
          }),
        };
      });
    },
    EliminarModuloFromSistema: (state, { payload: { sistema, modulo }}) => {
      const sIndex = state.presupuestoSelected.sistemas.findIndex(s => s.sistema === sistema.sistema && sistema.sistemaId === s.sistemaId && sistema.referencia === s.referencia && sistema.modulos.length === s.modulos.length);
      if(sIndex < 0) throw new Error('Error buscando el sistema');
      const mIndex = state.presupuestoSelected.sistemas[sIndex].modulos.findIndex((mod) => {
        return (
          mod.modulo === modulo.modulo &&
          mod.cantidad === modulo.cantidad &&
          mod.alto === modulo.alto &&
          mod.ancho === modulo.ancho
        );
      });
      if(mIndex < 0) throw new Error('Error buscando el modulo');
      state.presupuestoSelected.sistemas[sIndex].modulos.splice(mIndex, 1);
    },
    AgregarModuloToSistema: (state, { payload: { sistema, modulo } }) => {
      const index = state.presupuestoSelected.sistemas.findIndex(s => s.sistema === sistema.sistema && sistema.sistemaId === s.sistemaId && sistema.referencia === s.referencia && sistema.modulos.length === s.modulos.length);
      if(index < 0) throw new Error('Error buscando el sistema');
      state.presupuestoSelected.sistemas[index].modulos.push(modulo);
    },
    RefreshPrecioFinal: (state) => {
      state.presupuestoSelected.precioTotal = Number(state.presupuestoSelected.sistemas.reduce((acc, sistema) => {
        return acc + sistema.modulos.reduce((accM, modulo) => accM + Number(modulo.precioFinal), 0);
      }, 0).toFixed(2));
    },
    SetLoading: (state, { payload }) => {
      state.loading = payload.loading;
    }
  },
});


export const {
  RefrescarPresupuestos,
  AgregarPresupuesto,
  UpdatePresupuesto,
  LoadPresupuestoSelected,
  UpdatePresupuestoSelected,
  ActualizarValores,
  NewPresupuestoSelected,
  AddSistemaToPresupuesto,
  EliminarSistemaFromPresupuesto,
  EliminarModuloFromSistema,
  AgregarModuloToSistema,
  RefreshPrecioFinal,
  SetLoading,
} = presupuestosSlice.actions;

export default presupuestosSlice.reducer;