import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadMateriales } from "../../store/materiales/actions";
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";
import EditMaterial from "./components/EditMaterial";
import MainContext from "../../context/MainContext";

const Materiales: React.FC<any> = ({ actions, materiales, loading }) => {
  const { setErrorMessage } = useContext(MainContext);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const load = () => {
    actions
      .loadMateriales()
      .catch((e) =>
        setErrorMessage(
          "Hubo un error obtniendo materiales",
          e.message,
          "Materiales/useEffect"
        )
      );
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newMaterial = () => {
    setSelectedMaterial(null);
    setShowEdit(true);
  };

  const editClick = (item) => {
    setSelectedMaterial(item);
    setShowEdit(true);
  };

  const closeEdit = () => {
    setShowEdit(false);
    setSelectedMaterial(null);
  };

  return (
    <Panel title="Materiales" reload={load}>
      <Table
        columns={["Material", "Unidad", "Precio"]}
        data={materiales}
        keys={["material", "unidad", "precio"]}
        edit={editClick}
        loading={loading}
      />
      <footer className="presupuestos-footer">
        <Button color="green" className="ml-15" handleOnClick={newMaterial}>
          Nuevo
        </Button>
      </footer>
      <EditMaterial
        material={selectedMaterial}
        isOpen={showEdit}
        setIsOpen={closeEdit}
      />
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    materiales: state?.materiales?.materiales,
    loading: state?.materiales?.loading,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        loadMateriales,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(Materiales);
