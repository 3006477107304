import React from "react";
import "./Table.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Button from "../Button/Button";

const Table = (props) => {
  const editClick = (event, user) => {
    setClickedAnimation(event);
    props.edit(user);
  };
  const deleteClick = async (event, data) => {
    await setClickedAnimation(event);
    setTimeout(() => props.delete(data), 300);
  };
  const setClickedAnimation = async (event) => {
    event.preventDefault();
    const element = event.currentTarget.children[0];
    element.classList.add("clicked");
    await setTimeout(() => {
      element.classList.remove("clicked");
    }, 300);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {props.columns.map((title) => {
            return <th key={title}>{title}</th>;
          })}
          {props.duplicate ? <th>Duplicar</th> : null}
          {props.edit || props.editLink ? <th>Editar</th> : null}

          {props.delete ? <th>Eliminar</th> : null}
        </tr>
      </thead>
      <tbody>
        {props.loading ? (
          [0, 1, 2, 3, 4].map((item) => {
            return (
              <tr key={`skeleton-${item}`}>
                {props.columns.map((col) => (
                  <td key={`skeleton-td-${col}${item}`}>
                    <Skeleton />
                  </td>
                ))}
                {props.edit || props.editLink ? (
                  <td>
                    <Skeleton />
                  </td>
                ) : null}
                {props.delete ? (
                  <td>
                    <Skeleton />
                  </td>
                ) : null}
              </tr>
            );
          })
        ) : props.data.length === 0 ? (
          <tr>
            <td
              className="no-data"
              colSpan={
                props.columns.length +
                (props.editLink || props.edit ? 1 : 0) +
                (props.delete ? 1 : 0) +
                (props.duplicate ? 1 : 0)
              }
            >
              Sin datos
            </td>
          </tr>
        ) : null}
        {!props.loading && props.data.map((item, index) => {
          return (
            <tr key={`table-${index}`}>
              {(props.keys ?? Object.keys(item)).map((key) => {
                return <td key={`table-${index}-${key}`}>{item[key]}</td>;
              })}
              {props.duplicate ? (
                <td>
                  <Link
                    to={`${props.duplicate.to}${encodeURIComponent(
                      item[props.duplicate.key]
                    )}`}
                  >
                    <FontAwesomeIcon
                      className="edit-icon"
                      icon={faCopy as any}
                    ></FontAwesomeIcon>
                  </Link>
                </td>
              ) : null}
              {props.edit ? (
                <td>
                  <button
                    onClick={(event) => {
                      editClick(event, item);
                    }}
                    style={{ color: "#6CBEED" }}
                  >
                    <FontAwesomeIcon
                      className="edit-icon"
                      icon={faEdit as any}
                    ></FontAwesomeIcon>
                  </button>
                </td>
              ) : null}
              {props.editLink ? (
                <td>
                  <Link
                    to={`${props.editLink.to}${encodeURIComponent(
                      item[props.editLink.key]
                    )}`}
                  >
                    <FontAwesomeIcon
                      className="edit-icon"
                      icon={faEdit as any}
                    ></FontAwesomeIcon>
                  </Link>
                </td>
              ) : null}
              {props.delete ? (
                <td>
                  <Button
                    handleOnClick={(e) => deleteClick(e, item)}
                    className="delete-button"
                    saving={props.deleting(item)}
                    disabled={props.deleting(item)}
                    noHover
                  >
                    <FontAwesomeIcon
                      className="delete-icon"
                      icon={faTimes as any}
                    ></FontAwesomeIcon>
                  </Button>
                </td>
              ) : null}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
