import service from './../../services/serviceLayer';
import {
  RefrescarSistemas,
  AgregarSistema,
  UpdateSistema,
  SetLoading,
} from './slice';


export const refrescarMateriales = (materiales) => async (dispatch) => {

  //dispatch(RefrescarMateriales({ materiales }))
};

export const agregarSistema = (sistema) => async (dispatch) => {
  sistema.activo = true;
  const id = await service.insert('sistemas', sistema);
  sistema.id = id;
  dispatch(AgregarSistema({ sistema }))
};

export const eliminarSistema = (_sistema) => async dispatch => {
  const sistema = { ..._sistema };
  sistema.activo = false;
  await service.update('sistemas', sistema.id, sistema);  
  dispatch(UpdateSistema({ sistema }));
};

export const updateSistema = (sistema) => async dispatch => {
  await service.update('sistemas', sistema.id, sistema);
  dispatch(UpdateSistema({ sistema }));

}

export const loadSistemas = () => async (dispatch) => {
  dispatch(SetLoading({ loading: true }));
  const sistemas = await service.getAllCondition('sistemas', 'activo', '!=', false);
  dispatch(RefrescarSistemas({ sistemas }));
  dispatch(SetLoading({ loading: false }));
}
