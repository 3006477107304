import React, { useContext } from "react";
import Input from "../../../../components/Input/Input";
import MainContext from "../../../../context/MainContext";
import "./DataForm.scss";

import useMinimizer from "../../../../hooks/useMinimizer";
import { IPresupuesto } from "../../../../interfaces/IPresupuesto";
import { bindActionCreators } from "@reduxjs/toolkit";
import { updatePresupuestoSelected } from "../../../../store/presupuestos/actions";
import { connect } from "react-redux";

const DataForm = ({ state, actions }) => {
  const { setErrorMessage } = useContext(MainContext);
  const { hiddenClass, Icon } = useMinimizer(true);
  const handleChange = (e) => {
    try {
      actions.updatePresupuestoSelected(e.target.value, e.target.name);
    } catch (e) {
      setErrorMessage(
        "Hubo un error actualizando la información",
        e.message,
        "presupuesto/DataForm/handleChange"
      );
    }
  };
  return (
    <div className={`data-form ${hiddenClass}`}>
      <h3 className="title">
        Datos
        <Icon />
      </h3>
      <Input
        name="cliente"
        type="text"
        className="grid-item"
        placeholder="Cliente"
        label="Cliente"
        value={state?.datos?.cliente}
        handleChange={handleChange}
      />
      <Input
        type="text"
        name="referencia"
        className="grid-item"
        placeholder="Referencia"
        label="Referencia"
        value={state?.datos?.referencia}
        handleChange={handleChange}
      />
      <Input
        type="text"
        name="destinatario"
        className="grid-item"
        placeholder="Destinatario"
        label="Destinatario"
        value={state?.datos?.destinatario}
        handleChange={handleChange}
      />
      <Input
        type="email"
        name="email"
        className="grid-item"
        placeholder="Email"
        label="Email"
        value={state?.datos?.email}
        handleChange={handleChange}
      />
      <Input
        type="text"
        name="domicilio"
        className="grid-item"
        placeholder="Domicilio"
        label="Domicilio"
        value={state?.datos?.domicilio}
        handleChange={handleChange}
      />
      <Input
        type="text"
        name="nroPresupuesto"
        className="grid-item"
        placeholder="N° de Presupuesto"
        label="N° de Presupuesto"
        value={state?.datos?.nroPresupuesto}
        handleChange={handleChange}
      />
      <Input
        className="grid-item"
        type="text"
        name="telefono"
        placeholder="Telefono"
        label="Telefono"
        value={state?.datos?.telefono}
        handleChange={handleChange}
      />
      <Input
        className="grid-item"
        type="text"
        name="obra"
        placeholder="Obra"
        label="Obra"
        value={state?.datos?.obra}
        handleChange={handleChange}
      />
      <Input
        className="grid-item"
        type="text"
        name="titulo"
        placeholder="Titulo"
        label="Titulo"
        value={state?.datos?.titulo}
        handleChange={handleChange}
      />
      <Input
        className="grid-item"
        type="text"
        name="marcacion"
        placeholder="1.25"
        label="Marcación"
        value={`${state?.datos?.marcacion}`}
        handleChange={(e) => (e.target.value ? handleChange(e) : null)}
      />
      <label className="fwidth-item">
        <span>Bajada / Texto General</span>
        <textarea
          value={state?.datos?.bajada}
          onChange={(e) =>
            handleChange({ target: { ...e.target, name: "bajada" } })
          }
        ></textarea>
      </label>
      <label className="fwidth-item">
        <span>Pie</span>
        <textarea
          value={state?.datos?.pie}
          onChange={(e) =>
            handleChange({ target: { ...e.target, name: "pie" } })
          }
        ></textarea>
      </label>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state?.presupuestos?.presupuestoSelected || ({} as IPresupuesto),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updatePresupuestoSelected,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataForm);
