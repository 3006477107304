import firebaseService from './firebase';

export class ServiceLayer {
  /*service: any;
  
  constructor(service) {
    this.service = service;
  }*/

  async getAll(/*service,*/ collection) {
    const snapshot = await firebaseService.getAll(collection);
    const ret = snapshot.docs.map(doc => {
      const data = doc.data();
        return ({
          id: doc.id,
          ...data
        });
    });
    return ret;
  }

  async getAllCondition(collection, field, condition, value) {
    const snapshot = await firebaseService.collection(collection).where(field, condition, value).get();
    const ret = snapshot.docs.map(doc => {
      const data = doc.data();
        return ({
          id: doc.id,
          ...data
        });
    });
    return ret;
  }

  async insert (collection, data) {
    const id = await firebaseService.insert(collection, data);
    return id;
  }

  async update(collection, id, data) {
    await firebaseService.update(collection, id, data);
  }

  async delete(collection, id) {
    await firebaseService.delete(collection, id);
  }

  insertLog(message: string, source: string) {
    try {
      this.insert("logs", {
        message,
        source,
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export default new ServiceLayer();
