import React from "react";
import Skeleton from "react-loading-skeleton";

import "./Input.scss";

const Input: React.FC<any> = ({
  value,
  handleChange,
  label,
  placeholder,
  type,
  className,
  name,
  checked,
  skeleton,
  handleBlur,
}) => {
  return (
    <label className={className}>
      <span className="label">{label}</span>
      {skeleton ? (
        <Skeleton height={30} />
      ) : (
        <input
          className="input"
          name={name}
          placeholder={placeholder}
          type={type}
          onChange={handleChange}
          defaultValue={value}
          defaultChecked={checked}
          onBlur={handleBlur}
        />
      )}
    </label>
  );
};

export default Input;
