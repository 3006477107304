import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/free-solid-svg-icons";
import "./Presupuestos.scss";
import useAuthBlocker from "../../hooks/useAuthBlocker/useAuthBlocker";
import { bindActionCreators } from "redux";
import { loadPresupuestos } from "../../store/presupuestos/actions";
import { connect } from "react-redux";
import MainContext from "../../context/MainContext";

const FaArchive = faArchive as any;

const Presupuestos = ({ actions, presupuestos, loading }) => {
  const { isAuthorized, NotAuthorized } = useAuthBlocker("cotizador");
  const { setErrorMessage } = useContext(MainContext);

  const load = () => {
    actions
      .loadPresupuestos()
      .catch((e) =>
        setErrorMessage(
          "Error cargando presupuestos",
          e.message,
          "Presupuestos/useEffect"
        )
      );
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthorized) return <NotAuthorized />;
  return (
    <Panel title="Presupuestos" reload={load}>
      <Table
        columns={["No Presupuesto", "Cliente", "Obra", "Título", "Historial"]}
        keys={["nroPresupuesto", "cliente", "obra", "titulo", "historial"]}
        data={presupuestos.map((item) => ({
          ...item.datos,
          id: item.id,
          historial: (
            <Link to={`/presupuesto/${item.id}/historial/`}>
              <FontAwesomeIcon icon={FaArchive} />
            </Link>
          ),
        }))}
        editLink={{ to: "/presupuesto/", key: "id" }}
        loading={loading}
      />
      <footer className="presupuestos-footer">
        <Button color="green" className="ml-15" link="/presupuesto">
          Nuevo
        </Button>
      </footer>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  presupuestos: state?.presupuestos?.lista || [],
  loading: state?.presupuestos?.loading
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadPresupuestos,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Presupuestos);
