import React, { useEffect, useState, useContext } from "react";
import Modal from "../../../components/Modal/Modal";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import {
  updateMaterial,
  agregarMaterial,
} from "../../../store/materiales/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainContext from "../../../context/MainContext";

const EditMaterial: React.FC<any> = ({
  isOpen,
  setIsOpen,
  material,
  actions,
}) => {
  const { setErrorMessage } = useContext(MainContext);
  const { updateMaterial, agregarMaterial } = actions;

  const [newMaterial, setNewMaterial] = useState(null);
  const [newPrecio, setNewPrecio] = useState(null);
  const [newUnidad, setNewUnidad] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setNewMaterial(material?.material);
    setNewUnidad(material?.unidad);
    setNewPrecio(material?.precio);
  }, [material]);

  const save = async () => {
    try {
      setSaving(true);
      if (material) {
        await updateMaterial(material, newMaterial, newUnidad, newPrecio);
      } else {
        const item = {
          material: newMaterial,
          precio: newPrecio,
          unidad: newUnidad,
        } as any;
        await agregarMaterial(item);
      }
      setSaving(false);
      setNewMaterial(null);
      setNewPrecio(null);
      setNewUnidad(null);
      setIsOpen(false);
    } catch (e) {
      setErrorMessage(
        "Hubo un error guardando material",
        e.message,
        "Materiales/EditMaterial/save"
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)} height="450px">
      <Input
        className="fwidth-item"
        type="text"
        placeholder="Material"
        label="Material"
        value={`${newMaterial ?? ""}`}
        handleChange={(e) => setNewMaterial(e.target.value.replace(/ /g, ""))}
      />
      <Input
        className="fwidth-item"
        type="text"
        placeholder="Unidad"
        label="Unidad"
        value={`${newUnidad ?? ""}`}
        handleChange={(e) => setNewUnidad(e.target.value)}
      />
      <Input
        className="fwidth-item"
        type="number"
        placeholder="Precio"
        label="Precio"
        value={`${newPrecio ?? ""}`}
        handleChange={(e) => setNewPrecio(e.target.value)}
      />
      <Button
        color="green"
        className="fullwidth"
        handleOnClick={save}
        saving={saving}
      >
        Guardar
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapActionsToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateMaterial,
        agregarMaterial,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(EditMaterial);
