import React, { useState } from "react";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Variable: React.FC<any> = ({ index, v, getPartialCalculo, dispatch }) => {
  const [nombre, setNombre] = useState(v.nombre);

  return (
    <div className="flex flex-wrap variable-row">
      <div className="flex" style={{ width: "calc(100% - 90px)" }}>
        <Input
          value={`${nombre ?? ""}`}
          handleBlur={(event) =>
            dispatch({
              type: "update",
              payload: {
                data: { ...v, nombre: event.target.value },
                index,
              },
            })
          }
          handleChange={(e) => setNombre(e.target.value)}
          label="Nombre"
          placeholder="Nombre"
          type="text"
          className="grid-item hwidth-item"
        />
        <Input
          value={`${v.formula ?? ""}`}
          handleChange={(event) =>
            dispatch({
              type: "update",
              payload: {
                data: { ...v, formula: event.target.value },
                index,
              },
            })
          }
          label="Formula"
          placeholder="Formula"
          type="text"
          className="grid-item hwidth-item"
        />
      </div>
      <Input
        type="checkbox"
        checked={v.redondeo}
        handleChange={(event) =>
          dispatch({
            type: "update",
            payload: {
              data: { ...v, redondeo: event.target.checked },
              index,
            },
          })
        }
        label="Redondeo"
      />
      <label style={{ minWidth: "100px" }}>
        <span className="label flex" style={{ justifyContent: "center" }}>
          Parcial
        </span>
        <span
          style={{ height: "50px", fontWeight: 600, justifyContent: "center" }}
          className="flex flex-align-center"
        >
          ${getPartialCalculo(index)}
        </span>
      </label>
      <div
        className="flex justify-flex-end flex-align-center"
        style={{ transform: "translateY(5px)" }}
      >
        <Button
          handleOnClick={() =>
            dispatch({ type: "eliminar", payload: { index } })
          }
          className="delete-button"
          noHover
        >
          <FontAwesomeIcon
            className="delete-icon"
            icon={faTimes as any}
          ></FontAwesomeIcon>
        </Button>
      </div>
    </div>
  );
};

export default Variable;
