import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Panel from "../../components/Panel/Panel";
import { bindActionCreators } from "redux";
import { agregarSistema, updateSistema } from "../../store/sistemas/actions";
import { loadModulos } from "../../store/modulos/actions";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import ModuloMultiCheckbox from "./components/ModuloMultiCheckbox";
import MainContext from "../../context/MainContext";
import { ISistema } from "../../interfaces/ISistema";

const Sistema: React.FC<any> = ({ actions, sistemas, modulos }) => {
  const { id }: any = useParams();
  const sistema = sistemas.find((s) => s.id === id) || {};
  const [nombre, setNombre] = useState(sistema?.nombre);
  const [modsDisp, setModsDisp] = useState(sistema?.modulos_disponibles || []);
  const [saving, setSaving] = useState(false);
  const { setMessage, setErrorMessage } = useContext(MainContext);

  useEffect(() => {
    actions
      .loadModulos()
      .catch((e) =>
        setErrorMessage(
          "Error cargando modulos",
          e.message,
          "Sistema/useEffect"
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const guardar = async () => {
    try {
      setSaving(true);
      const _sistema: ISistema = { ...sistema };
      _sistema.nombre = nombre;
      _sistema.modulos_disponibles = modsDisp.map((m) => ({
        nombre: m.nombre,
      }));

      console.log("sistema", _sistema);

      if (id) actions.updateSistema(_sistema);
      else {
        if (
          sistemas.find(
            (s: ISistema) => s.nombre.toLowerCase() === nombre.toLowerCase()
          )
        ) {
          setSaving(false);
          setMessage({ type: "error", message: "Sistema ya existente" });
          return;
        }
        actions.agregarSistema(_sistema);
      }

      setMessage({ type: "success", message: "Sistema guardado exitosamente" });
    } catch (e) {
      setErrorMessage(
        "Hubo un error guardando sistema",
        e.message,
        "Sistema/guardar"
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <Panel title="Sistema">
      <Input
        value={sistema?.nombre}
        handleChange={(event) => setNombre(event.target.value)}
        label="Nombre"
        placeholder="Nombre"
        type="text"
        className="grid-item  hwidth-item"
        skeleton={!sistemas}
      />
      <ModuloMultiCheckbox
        data={modulos}
        label="Modulos Disponibles"
        values={modsDisp}
        setter={setModsDisp}
        skeleton={!modsDisp || !modulos}
        labelKey={["nombre"]}
      />
      <footer className="flex justify-flex-end flex-align-center">
        <Button
          className="ml-15"
          color="green"
          handleOnClick={guardar}
          saving={saving}
        >
          Guardar
        </Button>
        <Button className="ml-15" color="red" link="/sistemas">
          Volver
        </Button>
      </footer>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    sistemas: state?.sistemas?.lista || [],
    modulos: state?.modulos?.lista || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        agregarSistema,
        updateSistema,
        loadModulos,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sistema);
