import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SearchFilter from "../SearchFilter";

import "./MultiCheckbox.scss";

const MultiCheckbox: React.FC<any> = ({
  data,
  label,
  values,
  setter,
  skeleton,
  labelKey,
  valueField,
  showSearch,
}) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleChange = (e, index) => {
    if (e.target.checked) {
      setter([...values, data[index]]);
    } else {
      //setter(values.filter((item) => item !== e.target.value));
      setter(
        values.filter((item) =>
          valueField ? item.id !== data[index].id : item !== data[index]
        )
      );
    }
  };
  return (
    <section className="multicheckbox">
      <header className="multicheckbox-header mb-2 flex justify-space-between flex-align-center">
        {label}
        <SearchFilter list={data} setFilteredList={setFilteredData} />
      </header>
      {skeleton ? (
        <Skeleton height={30} />
      ) : (
        filteredData.map((item, index) => (
          <label
            className="multicheckbox-label"
            key={`${label}-${item}-${labelKey || ""}`}
          >
            <input
              onChange={(e) => handleChange(e, index)}
              type="checkbox"
              checked={
                valueField
                  ? values.find((v) => v[valueField] === item[valueField])
                  : values.includes(item)
              } //TODO: review if this should keep the id validation in case of objects
            />
            {labelKey
              ? Array.isArray(labelKey)
                ? labelKey.reduce((v, i) => `${v}${item[i]}-`, "").slice(0, -1)
                : item[labelKey]
              : item}
          </label>
        ))
      )}
    </section>
  );
};

export default MultiCheckbox;
